import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-politica-privacidad',
  templateUrl: './politica-privacidad.component.html',
  styleUrls: ['./politica-privacidad.component.scss']
})
export class PoliticaPrivacidadComponent implements OnInit {
  urlLogo = environment.recursos + 'nuevoestiloweb/logos/logo_tipos_destinux.svg';
  urlForms = environment.recursos + 'rgpd/';
  constructor() { }

  ngOnInit(): void {
  }

}
